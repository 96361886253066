import React from 'react';
import {
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react';

import AppStateProvider from "./AppStateProvider";

import Frame from './Frame';

const theme = extendTheme({
  "colors": {
    "blue": {
      "50": "#E6F7FF",
      "100": "#B8EAFF",
      "200": "#8ADCFE",
      "300": "#5DCFFE",
      "400": "#2FC1FE",
      "500": "#01B4FE",
      "600": "#0190CB",
      "700": "#016C98",
      "800": "#004866",
      "900": "#002433"
    },
    "yellow": {
      "50": "#FAF9EA",
      "100": "#F1EFC5",
      "200": "#E8E5A0",
      "300": "#E0DB7B",
      "400": "#D7D056",
      "500": "#CEC631",
      "600": "#A59E27",
      "700": "#7C771D",
      "800": "#524F14",
      "900": "#29280A"
    },
    "green": {
      "50": "#E9FCFA",
      "100": "#C1F6F0",
      "200": "#99EFE6",
      "300": "#71E9DD",
      "400": "#4AE3D3",
      "500": "#22DDCA",
      "600": "#1BB1A1",
      "700": "#148579",
      "800": "#0E5851",
      "900": "#072C28"
    },
    "red": {
      "50": "#F8EDED",
      "100": "#EBCCCD",
      "200": "#DEABAC",
      "300": "#D18A8C",
      "400": "#C4696C",
      "500": "#B7484B",
      "600": "#923A3C",
      "700": "#6E2B2D",
      "800": "#491D1E",
      "900": "#250E0F"
    },
    "orange": {
      "50": "#F8EDED",
      "100": "#EACCCD",
      "200": "#DDABAD",
      "300": "#D08B8D",
      "400": "#C36A6C",
      "500": "#B6494C",
      "600": "#913B3D",
      "700": "#6D2C2E",
      "800": "#491D1F",
      "900": "#240F0F"
    },
    "purple": {
      "50": "#F5EFF5",
      "100": "#E4D3E4",
      "200": "#D3B6D2",
      "300": "#C299C0",
      "400": "#B07DAF",
      "500": "#9F609D",
      "600": "#7F4D7E",
      "700": "#5F3A5E",
      "800": "#40263F",
      "900": "#20131F"
    },
    "pink": {
      "50": "#FAEBF1",
      "100": "#F1C6D7",
      "200": "#E7A1BE",
      "300": "#DE7DA4",
      "400": "#D5588B",
      "500": "#CC3371",
      "600": "#A3295A",
      "700": "#7A1F44",
      "800": "#51152D",
      "900": "#290A17"
    }
  }
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppStateProvider>
        <Frame />
      </AppStateProvider>
    </ChakraProvider>
  );
}

export default App;
