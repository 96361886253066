import { useEffect } from 'react';
import {
  Text, Card, CardHeader, Heading, CardBody, 
  CircularProgress, Stack,
  Center
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";


export default function Payment() {
  const { appState, setAppState } = useAppStateStore();

  useEffect(() => {    
    fetch(appState.endpoint + 'session?id=' + appState.session_id, {
      method : "GET"
    })
    .then((res) => {
      res.json().then((data) => {
        setAppState({ ...appState, payment : data.payment, });
      });
    })
    .catch((err) => {
      console.error(err);
      alert('error');
    });        
  }, []);

  if (appState.payment) {
    if ("paid" === appState.payment.payment_status) {    
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Thanks!</Heading>
          </CardHeader>
        
          <CardBody>
            <Text pt='2' fontSize='md'>Your payment of 
            ${parseFloat(appState.payment.amount_total / 100).toFixed(2)} 
            &nbsp; was successful. Your order will be on the way soon!</Text>              
          </CardBody>
        </Card>
      );    
    }
    else {
      return (
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Uh Oh&hellip;</Heading>
          </CardHeader>
        
          <CardBody>
            <Text pt='2' fontSize='md'>
            There appears to have been a problem processing your payment. Please try 
            again or contact us. 
            </Text>              
          </CardBody>
        </Card>
      );        
    }
  }
  else {
    return (
      <Card  width={{base: '90%', lg: "50%"}}>
        <CardHeader>
          <Heading size='md'>Fetching information&hellip;</Heading>
        </CardHeader>
      
        <CardBody>
          <Center><CircularProgress value={33} size='120px' /></Center>
        </CardBody>
      </Card>
    );
  }
 
}