import { Card, CardBody, List, ListItem, ListIcon, Stack, Link, Text } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export default function Resources() {

  return (
  <>
    <Card maxW='md' float='left' mr='5' mb='8'>
      <CardBody>
        <Stack mt='6' spacing='3'>
          <Text size='md' mb='8'>
            Looking for more information about the state of legal oversight and protection
            for homeschooled children in the USA and Canada? Want to get involved? Check out
            the great resources and work being done by the folks at the Coalition for
            Responsible Home Education:
          </Text>
          <Text size='md'>
            <Link href='https://responsiblehomeschooling.org/' isExternal>
              Coalition for Responsible Home Education <ExternalLinkIcon mx='2px' />
            </Link>          
          </Text>
        </Stack>
      </CardBody>
    </Card>  

    <Card maxW='md' float='left' mr='5' mb='8'>
      <CardBody>
        <Stack mt='6' spacing='3'>
          <Text size='md' mb='8'>
            Are you a current or former homeschool student looking for resources on how to
            make a life in the outside world? Check out this great blog with resources by
            child and survivor advocate R.L. Stollar:
          </Text>
          <Text size='md'>
            <Link href='https://rlstollar.com/2023/06/13/a-survival-guide-for-homeschool-alumni/' isExternal>
              R.L. Stollar, Child Liberation Theologian, Child and Survivor Advocate <ExternalLinkIcon mx='2px' />
            </Link>          
          </Text>
        </Stack>
      </CardBody>
    </Card>  

    <Card maxW='md' float='left' mr='5'>
      <CardBody>
        <Stack mt='6' spacing='3'>
          <Text size='md' mb='8'>
            Looking for more stories and blog posts by former homeschoolers as well as
            &ldquo;Quiverfull&rdquo; survivors and survivors of related fundamentalist patriarchal
            communities? Check out this now-archived resource which was very impactful for
            Joy as she began coming to terms with her own past: 
          </Text>
          <Text size='md'>
            <Link href='https://homeschoolersanonymous.net/' isExternal>
              Homeschoolers Anonymous <ExternalLinkIcon mx='2px' />
            </Link>          
          </Text>
        </Stack>
      </CardBody>
    </Card>  
  </> 
  );
}

