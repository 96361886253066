import { useAppStateStore } from "./AppStateProvider";
import { useEffect } from 'react';
import Buy from "./Buy";
import Resources from "./Resources";
import About from "./About";
import Payment from "./Payment";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

interface Props {
  children: React.ReactNode
}

const Links = ['Buy the Book', 'Resources & More', 'About Joy & Harmony'];

const NavLink = (props: Props) => {
  const { children } = props;
  const { appState } = useAppStateStore();
  
  const color900 = () => {
    if ('buy' === appState.status || 'payment' === appState.status) {
      return 'blue.700';
    }
    else if ('resources' === appState.status) {
      return 'red.700';
    }
    else {
      return 'yellow.700';
    }
  };
  
  const page = () => {
    if ('Buy the Book' === children) {
      return '#buy';
    }
    else if ('Resources & More' === children) {
      return '#resources';
    }
    else {
      return '#about';
    }
  }
  
  const fontColor = () => {
    if ('buy' === appState.status || 'payment' === appState.status) {
      return '#ffffff';
    }
    else if ('resources' === appState.status) {
      return '#ffffff';
    }
    else {
      return '#222222';
    }
  }
  
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        color : '#ffffff',
        bg: useColorModeValue(color900(), color900()),
      }}
      color={fontColor()}
      fontWeight='bold'
      fontSize='24'
      onClick={() => 'pressed'}
      sx={{'WebkitTextStrokeWidth': '1px', 'WebkitTextStrokeColor': '#888888', 'TextStrokeWidth': '1px', 'TextStrokeColor': '#888888'}}
      href={page()}>
      {children}
    </Box>
  )
}

function ActiveComponent() {
  const { appState } = useAppStateStore();
  
  if ('buy' === appState.status) {
    return (<Buy />);
  }
  else if ('resources' === appState.status) {
    return (<Resources />);
  }
  else if ('about' === appState.status) {
    return (<About />);
  }
  else if ('payment' === appState.status) {
    return (<Payment />);
  }
}


export default function Frame() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { appState, setAppState } = useAppStateStore();
  
  useEffect(() => {
    window.addEventListener("hashchange", () => {      
      const hash = window.location.hash.replace('#', '') || 'buy';
      setAppState({ ...appState, status : hash, });    
    });
    
    const hash = window.location.hash.replace('#', '') || 'buy';
        
    const urlParams  = new URLSearchParams(window.location.search);
    const session_id = urlParams.get('session_id') || '';    
    
    setAppState({ ...appState, status : hash, session_id : session_id, });
  }, []); 

  const color500 = () => {
    if ('buy' === appState.status || 'payment' === appState.status) {
      return 'blue.500';
    }
    else if ('resources' === appState.status) {
      return 'red.500';
    }
    else {
      return 'yellow.500';
    }
  };

  const color900 = () => {
    if ('buy' === appState.status || 'payment' === appState.status) {
      return 'blue.900';
    }
    else if ('resources' === appState.status) {
      return 'red.900';
    }
    else {
      return 'yellow.900';
    }
  };
  
  const background = () => {
    if ('buy' === appState.status || 'payment' === appState.status) {
      return "url('/blue-bg.jpg')";
    }
    else if ('resources' === appState.status) {
      return "url('/red-bg.png')";
    }
    else {
      return "url('/yellow-bg.png')";
    }
  };  
  
  return (
    <>
      <Box bg={useColorModeValue(color500(), color900())} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Image src='/art.png' style={{height: 160, width: 200, marginTop: 80}} alt="A red ground and blue sky with stick figures getting sucked up into the sky in a yellow vortex" />
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box 
        pt={[100, 100, 10]}
        pl={[10, 10, 250]}
        bgImage={background()}
        bgRepeat="repeat"
        minHeight='1000'
        
      ><ActiveComponent /></Box>
    </>
  )
}
