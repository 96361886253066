import { Card, CardBody, Stack, Heading, Text } from '@chakra-ui/react'

export default function About() {

  return (
  <>
    <Card maxW='md' float={['none', 'none', 'none', 'none', 'left']} mr='5'>
      <CardBody>
        <Stack mt='6' spacing='3'>
          <Heading size='md'>Joy Miller</Heading>
          <Text>
            Joy Miller was homeschooled by a family steeped in Evangelical Fundamentalist
            Christianity. Born in the U.S. Midwest, she grew up expecting the apocalypse
            and dreaming of being a writer.
          </Text>
          <Text>
            Now in her mid 30s, Joy mainly writes when her young daughter is napping. She
            is an enthusiastic participant in her local Episcopal church as well as the
            alcoholism recovery and LGBTQ+ communities. Joy believes that children are
            people with rights, and that sensible legislation and oversight to protect
            homeschooled children in the United States and Canada is a currently urgent
            issue.
          </Text>
        </Stack>
      </CardBody>
    </Card>  

    <Card maxW='md' mt={[10, 10, 10, 10, 0]}>
      <CardBody>
        <Stack mt='6' spacing='3'>
          <Heading size='md'>Harmony Miller</Heading>
          <Text>
            Throughout her early childhood, Harmony Miller was homeschooled along with her
            older sister. Transitioning from her insular life within the homeschool
            fundamentalist subculture into the public school system introduced Harmony to
            a world of peers and a time of re-evaluating many of the viewpoints and
            beliefs she'd been taught as a child. 
          </Text>
          <Text>
            Harmony continues playing and teaching music along with her long-time partner.
          </Text>
        </Stack>
      </CardBody>
    </Card> 


    <Card maxW='md' mt={10}>
      <CardBody>
        <Stack mt='6' spacing='3'>
          <Heading size='md'>Contact Us</Heading>
          <Text>
             <a href="mailto:joyandharmonymiller@gmail.com" target="_blank">joyandharmonymiller@gmail.com</a> 
          </Text>
        </Stack>
      </CardBody>
    </Card> 
  </> 
  );
}

