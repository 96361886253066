import { Card, CardBody, CardFooter, Image, Stack, Heading, Text, 
  Divider, ButtonGroup, Button, VStack, Input, Badge, Radio, RadioGroup,
  Spinner, Center } from '@chakra-ui/react'
import { useAppStateStore } from "./AppStateProvider";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useEffect } from 'react';

const stripePromise = loadStripe('pk_live_zLpvgCMFCrwHNWf2gOxn4VkO');

function Start() {
  return (
    <Image
      src='/back.png'
      alt='Image of the back cover'
    />
  );
}

function Shipping() {
  const { appState, setAppState } = useAppStateStore();

  const updateField = (evt) => {
    const shipTo = { ...appState.shipTo, [evt.target.name]: evt.target.value, message: '', update: '', };
    setAppState({ ...appState, shipTo: shipTo, });
  };

  return (
    <VStack spacing={4} align='stretch'>
      <Text fontSize='lg'>Please enter your shipping address:</Text>
      <Input variant='outline' placeholder='Ship to Name' name='name' value={appState.shipTo.name} onChange={updateField} />
      <Input variant='outline' placeholder='Mailing Address' name='street1' value={appState.shipTo.street1} onChange={updateField} />
      <Input variant='outline' placeholder='Address Line 2 (optional)' name='street2' value={appState.shipTo.street2} onChange={updateField} />
      <Input variant='outline' placeholder='Town' name='city' value={appState.shipTo.city} onChange={updateField} />
      <Input variant='outline' placeholder='State (e.g. NC)' name='state' value={appState.shipTo.state} onChange={updateField} />
      <Input variant='outline' placeholder='Zip Code' name='zip' value={appState.shipTo.zip} onChange={updateField} />
      <Input variant='outline' placeholder='Email' name='email' value={appState.shipTo.email} onChange={updateField} />
      {
        appState.shipTo.message &&
        <Badge colorScheme='red'>{appState.shipTo.message}</Badge>
      }
    </VStack>  
  );
}

function Rates() {
  const { appState, setAppState } = useAppStateStore();

  const sortRates = (a, b) => {
    if (parseFloat(a.cost) < parseFloat(b.cost)) { 
      return -1; 
    }
    else if (parseFloat(a.cost) > parseFloat(b.cost)) { 
      return 1; 
    }
    else { 
      return 0; 
    }
  };

  const rates = appState.shipTo.rates.sort(sortRates);

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });  

  const setShipping = (evt) => {    
    const id     = evt.target.value;
    const shipTo = { ...appState.shipTo, selectedShipping : id, };
    const amount = rates.reduce((prev, curr) => {
      if (curr.id === id) {
        return (prev + parseFloat(curr.cost));        
      }
      else {
        return prev;
      }
    }, 14.0);
    
    const cost = `${USDollar.format(amount)} including shipping`;
    
    setAppState({ ...appState, shipTo: shipTo, cost : cost, amount: amount, });
  };

  const choices = rates.map((r) => {
    return (
      <Radio value={r.id} style={{clear: 'both'}} onChange={setShipping} key={r.id} mb='8'>
      ${r.cost} {r.by} {r.name}
      {
        r.days && <>: Est. days: {r.days}</>
      }
      {
        r.terms && <> ({r.terms})</>
      }
      </Radio> 
    );
  });  
  
  const checkoutClick = () => {
    setAppState({ ...appState, checkout: 'payment', });
  };
  
  return (
    <>
      <Text fontSize='lg' mb='10'>
        Select a Shipping Method &amp; Click Button
      </Text>
      <RadioGroup name="shipChoice"> 
        {choices}
      </RadioGroup>
      <Button variant='solid' colorScheme='blue' onClick={checkoutClick}>
        Continue
      </Button>      
    </>
  );
}

function Payment() {
  const { appState, setAppState } = useAppStateStore();
  
  useEffect(() => {
    const shipTo = { 
      ...appState.shipTo, 
      loading : true, 
      session : '', 
      update  : 'Creating Session'
    };
    setAppState({ ...appState, shipTo: shipTo, });
    
    const shipby = appState.shipTo.rates.reduce((prev, curr) => {
      if (curr.id === appState.shipTo.selectedShipping || parseFloat(curr.cost) === parseFloat(appState.amount)) {
        return `${curr.by} ${curr.name}`;
      }
      else {
        return prev;
      }
    }, 'local');
        
    fetch(appState.endpoint + 'session', {
      method : 'POST',
      body   : JSON.stringify({
        amount  : (appState.amount * 100),
        name    : appState.shipTo.name, 
        street1 : appState.shipTo.street1, 
        street2 : appState.shipTo.street2,
        city    : appState.shipTo.city,
        state   : appState.shipTo.state, 
        zip     : appState.shipTo.zip,
        email   : appState.shipTo.email,
        shipby  : shipby,
      })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          const shipTo = { 
            ...appState.shipTo, 
            loading: false, 
            session: data['session'], 
          };
        
          setAppState({ 
            ...appState, 
            shipTo : shipTo, 
          });
        }
        else {
          setAppState({ ...appState, loading: false, });
          alert('Error processing data. Please try again.');
        }
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });    
  }, []);
  
  if (appState.shipTo.session) {
    const fetchClientSecret = () => {
      return appState.shipTo.session.client_secret;
    };

    const options = {fetchClientSecret};

    return (            
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>          
    );
  }
  else {
    return (
      <Center>     
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />      
      </Center>
    );
  }
}

function CheckoutStep() {
  const { appState } = useAppStateStore();

  if (!appState.checkout) {
    return (<Start />);
  }
  else if ('address' === appState.checkout) {
    return (<Shipping />);
  }
  else if ('rates' === appState.checkout) {
    return (<Rates />);
  }
  else if ('payment' === appState.checkout) {
    return (<Payment />);
  }
}



function CheckoutButton() {
  const { appState, setAppState } = useAppStateStore();

  const checkoutClick = () => {
    if (!appState.checkout) {
      setAppState({ ...appState, checkout: 'address', });
    }
    else if ('address' === appState.checkout) {
      if ('local' === appState.shipTo.street1.trim().toLowerCase()) {
        const shipTo = { ...appState.shipTo, selectedShipping : 'local', };
        setAppState({ 
          ...appState, 
          message  : '', 
          update   : '', 
          loading  : false, 
          cost     : '$14.00 local pickup', 
          amount   : 14.0, 
          shipTo   : shipTo,
          checkout : 'payment'
        });
        
        return true;
      }
      else if (!(appState.shipTo.street1 && appState.shipTo.city && appState.shipTo.state && appState.shipTo.zip)) {
        const shipTo = { ...appState.shipTo, message: "Please provide a complete mailing address", };
        setAppState({ ...appState, shipTo: shipTo, });
        return false;
      }
      else if (!appState.shipTo.email.match(/\w@\w/)) {
        const shipTo = { ...appState.shipTo, message: "Please provide a deliverable email address", };
        setAppState({ ...appState, shipTo: shipTo, });
        return false;
      }
    
      const shipTo = { ...appState.shipTo, loading: true, message : '', update : 'Validating Address', };
      setAppState({ ...appState, shipTo: shipTo, });
      
      let address_id = '';
      let parcel_id  = '';
      let weight     = 1000;

      fetch(appState.mbx + 'address', {
        method : "POST",
        body   : JSON.stringify(appState.shipTo)
      })
      .then((res) => {
        res.json().then((data) => {
          if (data.is_valid) {
          
            address_id = data.object_id;
            
            const shipTo = { 
              ...appState.shipTo, 
              loading    : true, 
              isValid    : true, 
              message    : '', 
              address_id : data.object_id,
              update     : 'Creating Pkg record',
              books      : [{
                dimensions : {
                  weight : { value : 0.5 },
                  height : { value : 8.5 },
                  thickness : { value : 0.5 },
                  width  : { value : 5.5 }
                }
              }]
            };          
            setAppState({ ...appState, shipTo : shipTo,  });
              
            fetch(appState.mbx + 'package', {
              method : "POST",
              body   : JSON.stringify(shipTo)
            })
            .then((res) => {
              res.json().then((data) => {
              
                if ('OK' === data.status) {
                  
                  parcel_id = data.object_id;
                  weight    = data.weight;
                
                  const shipTo = { 
                    ...appState.shipTo, 
                    loading    : true, 
                    isValid    : true, 
                    message    : '', 
                    address_id : address_id,
                    weight     : data.weight,
                    update     : 'Getting costs',
                    parcel_id  : data.object_id, 
                  };          
                  setAppState({ ...appState, shipTo : shipTo, });  
                  
                  fetch(appState.mbx + 'shipment', {
                    method : "POST",
                    body   : JSON.stringify({
                      parcel_id : parcel_id,
                      ship_to   : address_id,
                      weight    : weight,
                    })
                  })
                  .then((res) => {
                    res.json().then((data) => {
                      const shipTo = { 
                        ...appState.shipTo, 
                        loading     : false, 
                        isValid     : true, 
                        message     : '', 
                        address_id  : address_id,
                        shipment_id : data.object_id, 
                        parcel_id   : parcel_id,
                        rates       : data.rates,
                        update      : '',                                                
                      };   

                      setAppState({ ...appState, shipTo : shipTo, checkout : 'rates', });                          
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                    const shipTo = { ...appState.shipTo, loading: false, isValid: true, open: true, message: 'Error creating shipment object', };
                    setAppState({ ...appState, shipTo: shipTo, });
                  });                         
                }
                else {
                  const shipTo = { ...appState.shipTo, loading: false, isValid: true, open: true, message: 'Error creating parcel object', };
                  setAppState({ ...appState, shipTo: shipTo, });
                }
              });
            })
            .catch((err) => {
              console.error(err);
              const shipTo = { ...appState.shipTo, loading: false, isValid: true, open: true, message: 'Error creating parcel object', };
              setAppState({ ...appState, shipTo: shipTo, });
            });        
          }
          else {
            const shipTo = { ...appState.shipTo, loading: false, isValid: false, message: data.message, };
            setAppState({ ...appState, shipTo: shipTo, });
          }
        })
      })
      .catch((err) => {
        console.error(err);
        const shipTo = { ...appState.shipTo, loading: false, isValid: false, };
        setAppState({ ...appState, shipTo: shipTo, });
        alert('error');
      });              
    }
    else if ('rates' === appState.checkout) {
      setAppState({ ...appState, checkout: 'payment', });
    }
  };

  if (!appState.checkout) {
    return (
      <Button variant='solid' colorScheme='blue' onClick={checkoutClick}>
        Buy now
      </Button>
    );
  }
  else if ('address' === appState.checkout) {
    return (
      <Button variant='solid' colorScheme='blue' onClick={checkoutClick} disabled={!appState.shipTo.loading} isLoading={appState.shipTo.loading} loadingText={appState.shipTo.update}>
        Continue&hellip;
      </Button>
    );
  }
  else if ('rates' === appState.checkout) {
    return (
      <Button variant='solid' colorScheme='blue' onClick={checkoutClick}>
        Select Shipping Method
      </Button>
    );
  }
  
}


export default function Buy() {
  const { appState } = useAppStateStore();


  return (
  <>
    <Card maxW={['lg', 'lg', 'lg', 'lg', 'md']} float={['none', 'none', 'none', 'none', 'left']} mr='5'>
      <CardBody>
        <Image
          src='/front.png'
          alt='Image of the book cover'
        />
        <Stack mt='6' spacing='3'>
          <Heading size='md'>Living in the Shadow of the Heavenly Vacuum Cleaner</Heading>
          <Heading size="sm">An Evangelical Fundamentalist Childhood</Heading>
          <Text>
            by Joy &amp; Harmony Miller
          </Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <ButtonGroup spacing='10'>
          <CheckoutButton />
          <Text pt={3}>{appState.cost}</Text>
        </ButtonGroup>
      </CardFooter>
    </Card>  

    <Card maxW={['lg', 'lg', 'lg', 'lg', 'xl']} mt={[10, 10, 10, 10, 0]}>
      <CardBody>
        <CheckoutStep />
      </CardBody>
    </Card> 
  </> 
  );
}

