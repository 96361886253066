import { createContext, useState, useContext } from "react";

const AppStateStore = createContext();
export const useAppStateStore = () => useContext(AppStateStore);


export const initialState = {
  status     : 'buy',
  endpoint   : 'https://ahpn9kbiuj.execute-api.us-east-1.amazonaws.com/1/',
  mbx        : 'https://lp915p57lg.execute-api.us-east-1.amazonaws.com/4/',
  checkout   : '', 
  cost       : '$14.00 plus shipping',
  amount     : 18.13,
  shipTo     : {
    name    : '',
    street1 : '',
    street2 : '',
    city    : '',
    state   : '', 
    zip     : '',
    email   : '',
    message : '',
    loading : false,
    update  : '',
  }
};

export default function AppStateProvider({children}) {
  const [appState, setAppState] = useState(initialState);
  
  return (
    <AppStateStore.Provider value={{ appState, setAppState }}>
      {children}
    </AppStateStore.Provider>
  );
}